import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactForm from "../components/ContactForm"

import estimations_expertises_img from "../images/images/img_section/estimations_expertises.png"
import { Button, Modal } from "antd"

const Expertises = () => {
//Hooks
const [dataContact, setDataContact] = useState(false)

//Modal
const [showModalContact, setShowModalContact] = useState(false)

const showModalContactForm = (value_i) => {
  setShowModalContact(true)
  setDataContact(value_i)
}

const handleContactFormOk = e => {
  setShowModalContact(false)
}

const handleContactFormCancel = e => {
  setShowModalContact(false)
}

return (
  <>
  <Layout>
    <SEO title="Estimations et Expertises" />

    <div className="slideshow">
      <div className="usp">
        <div  className="usp-txt-header">Estimations et Expertises</div>
      </div>
    </div>
    <div className="border-gold-bottom"></div>


    <section>
      <div className="savoir-faire-content">
        <img src={estimations_expertises_img}  alt='Bijoux, Estimations et Expertises'/>
        <div>
          <div>
            <p  className="p-2 p-font" style={{paddingBottom: 0}}><strong>Pour les assurances : </strong>en cas de vols, cambriolages, accidents.</p>
            <p className="p-2 p-font"><strong>Un héritage : </strong>pour la valorisation de vos bijoux.</p>
          </div>
          <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Estimations et Expertises') }>Nous contacter</Button></div>
        </div>
      </div>
    </section>

    <Modal title="Formulaire de contact"
        style={{minWidth: '40vw', height: '80vh'}}
      visible={ showModalContact }
      onOk={ handleContactFormOk }
      onCancel={ handleContactFormCancel }
      okButtonProps={{ style: { display: 'none' } }}
    >

      <ContactForm page={dataContact} />

    </Modal>

  </Layout>
  </>
)
}

export default Expertises
